import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import Button from '@material-ui/core/Button';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import GetAppIcon from '@material-ui/icons/GetApp';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.1.266/pdf.worker.js`;


const useStyles = makeStyles(styles);

export default function UserGuide() {

  const classes = useStyles();
  const [numPages, setNumPages] = React.useState(null);
  const [objZoom, setObjZoom] = React.useState(0.7);
  const [pageNumber, setPageNumber] = React.useState(1);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  }
  const handleNext = () => {
    if (pageNumber < numPages - 2) {
      setPageNumber(pageNumber + 2);
    }
  }

  const handlePrev = () => {
    if (pageNumber > 2) {
      setPageNumber(pageNumber - 2);
    }
  }

  return (
    <div>
      <GridContainer>
        <GridItem sm={7} md={7}>
          <Button color="primary" size="large" style={{ float: "right" }}
            onClick={() => { setObjZoom(objZoom => objZoom + 0.1) }}>
            <ZoomInIcon /> Zoom In
          </Button>
          <Button color="primary" size="large" style={{ float: "right" }} disabled>----</Button>
          <Button color="primary" size="large" style={{ float: "right" }}
            onClick={() => { setObjZoom(objZoom => objZoom - 0.1) }}>
            <ZoomOutIcon /> Zoom Out
          </Button>
        </GridItem>
        <GridItem sm={5} md={5}>
          <Button color="primary" size="large" style={{ float: "right" }}
            onClick={() => { window.open("/userguide.pdf", "_blank") }}>
            <GetAppIcon /> Download
          </Button>
        </GridItem>
      </GridContainer>
      <div style={{ zoom: objZoom }}>
        <GridContainer >
          <GridItem sm={1} md={1}>
            <Button onClick={handlePrev}><NavigateBeforeIcon style={{ fontSize: "100px" }} /></Button>
          </GridItem>
          <GridItem sm={10} md={5}>
            <Document file="/userguide.pdf" onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={pageNumber} />
            </Document>
            <p>Page {pageNumber} of {numPages}</p>
          </GridItem>
          <GridItem sm={10} md={5}>
            <Document file="/userguide.pdf" onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={pageNumber + 1} />
            </Document>
            <p>Page {pageNumber + 1} of {numPages}</p>
          </GridItem>
          <GridItem sm={1} md={1}>
            <Button onClick={handleNext}><NavigateNextIcon style={{ fontSize: "100px" }} /></Button>
          </GridItem>

        </GridContainer>
      </div>
    </div>
  );

}