import React from "react";
// react plugin for creating charts
// import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "components/Grid/GridContainer.js";





import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

import { useGlobal } from 'reactn';

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  const [user] = useGlobal('user')
  

  

  React.useEffect(() => {
    
  }, [])

  




  

  

  

  

  

  return (
    <div>
      <GridContainer>
        <h5 style={{color: 'white', textAlign: 'center'}}>You are NOT Authorized to Access this Application. Please fill the below form to request Access</h5>
      <iframe src="https://forms.office.com/Pages/ResponsePage.aspx?id=fa-Hi0eGx02N9F9pogEbtTEnpkj7eElEl0xXKYY_9TpUOVlXNTE4TEVTWUhOSDYzOTFEUjkxMTFKVy4u&embed=true" 
                                height="850px" width="100%"> 
    </iframe>
      </GridContainer>
    </div>
  );
}
