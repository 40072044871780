import Dashboard from "@material-ui/icons/Dashboard";
import ImportContacts from "@material-ui/icons/ImportContacts";
import CloudUpload from "@material-ui/icons/CloudUpload";
import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import UnAuthPage from "views/Unauthorized/Unauthorized.js";
import UserGuide from "views/UserGuide/UserGuide.js";
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';
import LanguageIcon from '@material-ui/icons/Language';

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "AI Showcase",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/user"
  },
  {
    path: "/unauth",
    name: "AI Showcase",
    icon: Dashboard,
    component: UnAuthPage,
    layout: "/user"
  },
  {
    path: "/guide",
    name: "User Guide",
    icon: ImportContacts,
    component: UserGuide,
    layout: "/user"
  },
  
  
  
];

export default dashboardRoutes;
