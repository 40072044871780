import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DoneIcon from '@material-ui/icons/Done';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import { blackColor } from 'assets/jss/material-dashboard-react.js';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { FUNCTION_URL } from 'constants.js';

// const useStyles = makeStyles(styles);
import {
  whiteColor,
  grayColor
} from "assets/jss/material-dashboard-react.js";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  smallAvatar: {
    display: 'flex',
    width: "25px",
    height: "25px",
  },
  smallAvatarSuccess: {
    width: "25px",
    height: "25px",
    backgroundColor: "#004F69",
  },
  orange: {
    color: theme.palette.getContrastText(blackColor),
    backgroundColor: blackColor,
  },
  cardTitleWhite: {
    color: whiteColor,
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  log: {
    "font-family": "Courier New",
    fontSize: 16
  },

}));

export default function ApplicationDetails(props) {

  const classes = useStyles();
  const { app } = props
  const [flags, setFlags] = React.useState({
    finalChecks: 1
  });


  React.useEffect(() => {
    (async () => {
      if (app.appStatus === 0) {
        setFlags({ finalChecks: 0 });
      }
      else {
        await waitUntilUrlAvailable(app);

        setFlags({ finalChecks: 2 });
      }
    })();
  }, []);

  const waitUntilUrlAvailable = app => {
    return new Promise(async resolve => {
      fetch(FUNCTION_URL, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true'
        },
        body: JSON.stringify({
          "operation": 'ping',
          "app": app
        })
      }).then(() => { return resolve(); })
        .catch(() => { return resolve(); })
    });
  }

  const progressUpdate = (flag) => {
    return <div>
      {flag === 0 &&
        <ListItemAvatar>
          <Avatar><DoneIcon /></Avatar>
        </ListItemAvatar>}
      {flag === 1 &&
        <ListItemAvatar>
          <Avatar style={{ background: "#004F69" }}><i className="fa fa-refresh fa-spin fa-fw" aria-hidden="true"></i></Avatar>
        </ListItemAvatar>}
      {flag === 2 &&
        <ListItemAvatar>
          <Avatar className={classes.avatar} style={{ background: "#004F69" }}>
            <DoneIcon />
          </Avatar>
        </ListItemAvatar>}
    </div>
  }

  const url = () => {
    return (
      <Card style={{ backgroundColor: "#D3D3D3" }}>
        <CardHeader color="warning">
          <h4 className={classes.cardTitleWhite}>URL and Credentials</h4>
        </CardHeader>
        <CardBody>
          <div>
            <p>
              {flags.finalChecks === 2 && app.urls.map((x, i) =>
                <>
                  <b>{x.label || app.appTitle}: </b>
                  <a style={{ color: "#cf022b" }} href={x.url} target="_blank" rel="noopener noreferrer">{x.url}</a><br />
                  {x.creds &&
                    <>
                      <b>Authentication:</b><br />
                      {x.creds.map((y, j) =>
                        <>
                          {y.mode && <><b>Mode: </b>{y.mode}<br /></>}
                          {y.type && <><b>Type: </b>{y.type}<br /></>}
                          {y.username && <><b>Username: </b>{y.username}<br /></>}
                          {y.password && <><b>Password: </b>{y.password}<br /></>}
                          {j < x.creds.length - 1 && <br />}
                        </>)}
                    </>}
                  {i < app.urls.length - 1 && <><br /><Divider /><br /></>}
                </>)}
            </p>
          </div>

        </CardBody>
      </Card>
    )
  }

  return (
    <div style={{ backgroundColor: "#0F171E", height: "1080px" }}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card style={{ backgroundColor: "#D3D3D3" }} >
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Activity Log</h4>
            </CardHeader>
            <CardBody>
              <TextField id="req"
                variant="outlined" multiline fullWidth
                value={app.activityLog.sort((a, b) => b.timestamp - a.timestamp).map(x => `${(new Date(x.timestamp)).toISOString()} ${x.user.name} <${x.user.email}> triggered ${x.operation.toUpperCase()}`).join('\n\n')}
                style={{
                  margin: "25px 0px 0px 0px",
                  wordBreak: "break-all"
                }}
                label="Activity Log"
                InputProps={{
                  classes: {
                    input: classes.log,
                  },
                  style: {
                    wordBreak: "break-all"
                  }
                }} />
            </CardBody>
          </Card>

        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <Card style={{ backgroundColor: "#D3D3D3" }}>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Progress</h4>
            </CardHeader>
            <CardBody>
              <List>
                {
                  app.fakeSteps.map((x) => <>
                    <ListItem button key={x}>
                      {progressUpdate(app.appStatus)}
                      <ListItemText primary={x} />
                    </ListItem>
                    <Divider /></>)
                }
                <ListItem button key="step7">
                  {progressUpdate(flags.finalChecks)}
                  <ListItemText primary="Check Readiness" />
                </ListItem>
              </List>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          {app.appStatus === 2 && url()}
          {app.sales.length > 0 &&
            <Card style={{ backgroundColor: "#D3D3D3" }}>
              <CardHeader color="warning">
                <h4 className={classes.cardTitleWhite}>Sales </h4>
              </CardHeader>
              <CardBody>
                {app.sales.map(x =>
                  <>
                    {x.type === 'Video' && <OndemandVideoIcon style={{ color: "#cf022b", margin: "0px 5px -5px 15px" }} />}
                    {x.type === 'Presentation' && <PictureAsPdfIcon style={{ color: "#cf022b", margin: "0px 5px -5px 15px" }} />}
                    <a target="_blank" href={x.url}>{x.label || x.type}</a>
                    <br />
                  </>
                )}
              </CardBody>
            </Card>
          }
        </GridItem>

      </GridContainer>
    </div>
  );

}