import React from "react";

import { makeStyles } from "@material-ui/core/styles";



import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import FullScreenDialog from "components/Dialog/FullScreenDialog.js";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import ApplicationDetails from "views/ApplicationDetails/ApplicationDetails";
import { useGlobal } from 'reactn';
import { FUNCTION_URL } from 'constants.js';

import { useHistory } from "react-router-dom";

const useStyles = makeStyles(styles);


export default function Dashboard() {
  const history = useHistory();
  const [user] = useGlobal('user')
  const [open, setOpen] = React.useState(false);
  const [appList, setAppList] = React.useState([]);
  const [detail, setDetail] = React.useState("syndie");
  const [progressOn, setprogressOn] = React.useState(false);
  const [progressMessage, setProgressMessage] = React.useState("Getting Application List");
  const [application, setApplication] = React.useState({});

  const handleClose = (value) => {
    getStatusForAll();
    setOpen(false);
  };

  React.useEffect(() => {
    setprogressOn(true)
    whiteLogin();
  }, [])

  const getStatusForAll = () => {
    fetch(FUNCTION_URL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',

        'Access-Control-Allow-Credentials': 'true'
      },
      body: JSON.stringify({
        "name": "Azure",
        "user": user,
        "operation": "status"
      })
    })
      .then(data => { return data.json() })
      .then(jsondata => {
        console.log(jsondata)
        setAppList(jsondata);
        setprogressOn(false);
      });
  }


  const whiteLogin = () => {

    fetch(FUNCTION_URL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',

        'Access-Control-Allow-Credentials': 'true'
      },
      body: JSON.stringify({
        "operation": "login",
        "user": user
      })
    }).then(data => { return data.json() })
      .then(jsondata => {
        if (jsondata.success) {
          getStatusForAll();
        } else {
          history.push("/user/unauth");
        }
      });
  }

  const renderDetail = () => {
    return <ApplicationDetails app={application} />
  }

  const action = (action, app) => {
    if (action === "start") {
      setProgressMessage("Starting Application...This can take a while");
    }
    else {
      if (app.vmId === '') {
        return alert('Cannot stop');
      }

      setProgressMessage("Stopping Application...This can take a while");
    }

    setprogressOn(true)

    fetch(FUNCTION_URL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',

        'Access-Control-Allow-Credentials': 'true'
      },
      body: JSON.stringify({
        "user": "Azure",
        "user": user,
        "operation": action,
        "app": app
      })
    }).then(data => { return data.json() })
      .then(response => {
        if (action === "start") {
          openAppPage(app)
        }
        getStatusForAll();

      })
  }

  const getStatus = (value) => {
    if (value == 0) {
      return "Stopped"
    } else if (value === 1) {
      return "Deployment in Progress"
    } else {
      return "Running"
    }
  }

  var openAppPage = (app) => {
    setprogressOn(true);
    setProgressMessage("Fetching current status of " + app.appTitle)
    fetch(FUNCTION_URL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',

        'Access-Control-Allow-Credentials': 'true'
      },
      body: JSON.stringify({
        "app": app,
        "user": user,
        "operation": "appStatus"
      })
    })
      .then(data => { return data.json() })
      .then(jsondata => {

        setApplication(jsondata)
        setprogressOn(false);
        setDetail(app.appTitle);
        setOpen(true);
      });
  }

  return (
    <div>
      <GridContainer>
        {appList.map((app, index) => (
          <GridItem xs={12} sm={12} md={3} key={index}>
            <Card style={{ backgroundColor: "#253747", color: "white" }}>
              {app.appStatus === 2 ?
                <CardHeader color="danger" style={{ height: "25%" }}>
                  <img src={app.banner} alt="logo" style={{ width: "100%", cursor: "pointer" }} onClick={() => { openAppPage(app) }} />
                </CardHeader>
                :
                <CardHeader color="success" style={{ height: "25%" }}>
                  <img src={app.banner} alt="logo" style={{ width: "100%", cursor: "pointer" }} onClick={() => { openAppPage(app) }} />
                </CardHeader>
              }


              <CardBody>
                <h4 style={{ cursor: "pointer" }} onClick={() => { openAppPage(app) }}>{app.appTitle}</h4>
              </CardBody>
              <CardFooter chart>
                <div >
                  Status : {getStatus(app.appStatus)}
                </div>
                {app.appStatus === 2 ?
                  <i className="fa fa-stop fa-2x" style={{ cursor: "pointer", color: "#e53935" }} onClick={() => { action("stop", app) }} aria-hidden="true"></i>
                  :
                  <i className="fa fa-play fa-2x" style={{ cursor: "pointer", color: "green" }} onClick={() => { action("start", app) }} aria-hidden="true"></i>
                }

              </CardFooter>
            </Card>
          </GridItem>
        ))}
      </GridContainer>


      <Backdrop style={{ zIndex: 999, color: '#fff', textAlign: "center" }} open={progressOn}>
        <GridContainer>
          <GridItem key="one" xs={12} sm={12} md={12}>
            <CircularProgress color="inherit" />
          </GridItem>
          <GridItem key="two" xs={12} sm={12} md={12}>
            {progressMessage}
          </GridItem>
        </GridContainer>
      </Backdrop>
      <FullScreenDialog
        open={open}
        onClose={handleClose}
        title={application.appTitle}
        dialogContent={renderDetail()}
      />
    </div>
  );
}
