import {
  dangerColor
} from "assets/jss/material-dashboard-react.js";
import { infoColor } from "assets/jss/material-dashboard-react";
import { blackColor } from "assets/jss/material-dashboard-react";
import { headerColor } from "assets/jss/material-dashboard-react";
const fullScreenDialogStyle = theme => ({

  appBar: {
    position: 'relative',
    background: headerColor
  },

  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  Dialog1: {
    background: blackColor
  }

});

export default fullScreenDialogStyle;
