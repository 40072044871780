/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect  } from "react-router-dom";
import * as Keycloak from 'keycloak-js'
import { setGlobal } from 'reactn';
// core components
import Admin from "layouts/Admin.js";
import RTL from "layouts/RTL.js";
import { FUNCTION_URL } from 'constants.js';

import "assets/css/material-dashboard-react.css?v=1.8.0";


const hist = createBrowserHistory();

// keycloak init options
const initOptions = {
  url: 'https://sso-digital.apps.ocp4.innershift.sodigital.io/auth',
  realm: 'AutoBlock',
  clientId: 'AutoBlock',
  onLoad: 'login-required',
}
const keycloak = Keycloak(initOptions);



keycloak.init({ onLoad: initOptions.onLoad }).success((auth) => {

  console.log("auth", keycloak)
  if (!auth) {
    // window.location.reload();
    console.error("Authenticated Failed", auth);
  } else {

    
      setGlobal({
        "user": {
          name: keycloak.tokenParsed.name,
          email: keycloak.tokenParsed.email
        }
      });

    // localStorage.setItem("email", keycloak.tokenParsed.email);
    console.info("Authenticated");

  }

  //React Render
  ReactDOM.render(
    <Router history={hist}>
      <Switch>
        <Route path="/user" component={Admin} />
        <Redirect from="/" to="/user/dashboard" />
      </Switch>
    </Router>,
    document.getElementById("root")
  );

  localStorage.setItem("react-token", keycloak.token);

  localStorage.setItem("react-refresh-token", keycloak.refreshToken);

  setTimeout(() => {
    keycloak.updateToken(70).success((refreshed) => {
      if (refreshed) {
        console.debug('Token refreshed' + refreshed);
      } else {
        console.warn('Token not refreshed, valid for '
          + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
      }
    }).error(() => {
      console.error('Failed to refresh token');
    });


  }, 60000)

}).error((err) => {

  console.error("Authenticated Failed", err);
});

// ReactDOM.render(
//   <Router history={hist}>
//     <Switch>
//       <Route path="/admin" component={Admin} />
//       <Route path="/rtl" component={RTL} />
//       <Redirect from="/" to="/admin/dashboard" />
//     </Switch>
//   </Router>,
//   document.getElementById("root")
// );
